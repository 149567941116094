<style lang="sass" scoped>
.container-property
  // z-index: 1000
  .index-nav
    z-index: 1000
  .container-nav
    position: fixed
    left: 0
    top: 0
    width: 70px
    height: 100vh
    z-index: 1000
    background-color: darken(#f8f9fa, 7%)
    // border-right: solid 1px darken(#f8f9fa, 5%)

    .link
      width: 100%
      font-size: 12px
      font-weight: 700
      color: darken(#f8f9fa, 60%) !important
      &.active
        background-color: #f8f9fa

      &:hover
        background-color: #f8f9fa
        text-decoration: none

.container-left
  z-index: 900
  position: fixed
  top: 0px
  left: 70px
  height: 100vh
  // margin-top: 69px
  float: left
  width: 300px

  // position: sticky
  // top: 70px-1px
  // height: 90vh
  .container-left-top
    height: calc(100vh - 60px)
    overflow: scroll

    // background-color: #f8f9fa

  .container-left-bottom
    height: 60px
.container-right
  position: absolute
  right: 0
  // float: right
  // padding-left: 300px
  // margin-top: 69px
  width: calc(100% - 370px)
  height: 100%
  z-index: 1000
  overflow: scroll

.container-customer
  font-size: 12px
  .link
    color: darken(#f8f9fa, 60%)
    font-weight: bold
    line-height: 2rem
    &.active
      color: darken(#f8f9fa, 80%)
      background-color: darken(#f8f9fa, 7%)
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  line-height: 1.7rem
.required
  color: tomato
</style>
<template lang="pug">
div.container-property
  .container-nav
    //- <div class="d-flex flex-column" style='height: 100%'>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="mt-auto p-2">Flex item</div>
    //- </div>
    .d-flex.flex-column(style='height: 100%')
      div(style='height: 300px')
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#')
            span(style='font-size: 24px; line-height: 12px; font-weight: 900'): em N
          a.link.p-2.py-4.text-center.text-dark.active(href='#') 고객
          a.link.p-2.py-4.text-center.text-dark(href='#') 메시지
      div.mt-auto()
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#') 설정
          a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
          a.link.p-2.py-4.text-center.text-dark(href='#') 도움말



      //- a.link.p-2.py-4.text-center.text-dark(href='#') 설정
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 도움말

  div.container-left
    .container-left-top
      .row.justify-content-center.align-items-center(style='')
        .col
          .p-4.container-customer
            h5 고객
            a.link.pl-2.d-block(href='#') 가입 고객
            a.link.pl-2.d-block.active.rounded(href='#') 영업 고객
              a.float-right.link-config.mr-2(href='#') 설정

            .mb-4
            h5 관련 정보
            a.link.pl-2.d-block(href='#') 주문목록 (SQL)
            a.link.pl-2.d-block.rounded(href='#') 문의목록

    .container-left-bottom.border-top.shadow
      a.link.p-3.d-block(href='#') 새 목록 만들기

  div.container-right.bg-white.shadow-sm
    .container-filter.border-bottom.p-2
      a.link.ml-3(href='#') 전체 (777)
      a.link.ml-3(href='#') 저장된 필터1 (123)
      a.link.ml-3(href='#') 저장된 필터2 (98)
      a.link.ml-3(href='#') 더보기
      a.link.ml-3.text-primary.float-right(href='#') 새 필터 만들기
    .container-search.border-bottom.p-2
      form.form-inline.d-inline
        input.form-control.form-control-sm.rounded.bg-light(type='search' style='width: 300px')
        button.btn.btn-default.link-search.text-primary.btn-sm(href='#') 검색하기

      a.link.ml-3.text-primary.float-right(href='#') 메시지 보내기
      a.link.ml-3.text-primary.float-right(href='#') 새 고객 추가하기
    .row.justify-content-center.align-items-center
      .col
        .text-center
          p 오른쪽 컨테이너
          br

          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용
          .my-4 내용

  //- .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          strong  Noitaler
        h6.text-muted.text-center 새로운 계정 만들기
        .mb-4
        form.form(@submit.prevent='')
          .pt-4
          .form-group
            label.d-block: strong 이메일 주소
              strong.required.ml-1 *
            input.form-control(type='email' required)
            span.form-text.text-danger 이미 가입된 이메일 주소 입니다.
          .form-group
            label.d-block: strong 비밀번호
              strong.required.ml-1 *
            input.form-control(type='password' required)
            span.form-text.text-muted 비밀번호는 8자리 이상으로 입력해주세요.
          .form-group
            label.d-block: strong 비밀번호 확인
              strong.required.ml-1 *
            input.form-control(type='password' required)
            span.form-text.text-danger 비밀번호를 확인해주세요.
          .form-group
            label.d-block: strong 이름
              strong.required.ml-1 *
            input.form-control(type='text' required)

          .pt-4
          .form-group
            label.d-block: strong 비즈니스 이름 (사업자명)
              strong.required.ml-1 *
            input.form-control(type='text' required)
          .form-group
            label.d-block: strong 웹사이트 주소 URL
            input.form-control(type='text')
          .form-group
            label.d-block: strong 연락처
            input.form-control(type='text')
            b-form-checkbox(v-model='agree2' value='Y' unchecked-value='N') 이용안내 및 고객지원 전화수신 동의
          .form-group
            label.d-block: strong 가입경로
            textarea.form-control(style='font-size: 12px;' placeholder='예) 추천인 아이디, 쿠폰코드, 블로그, 카페')
          .form-group
            label.d-block: strong 문의 남기기
            textarea.form-control(style='font-size: 12px;' rows=5 placeholder='궁금하신 점을 남겨주세요. 초기 데이터 이전이 필요하거나 카카오알림톡 발송은 서비스내 고객센터를 통해 안내드립니다.')

          .pt-4
          .form-group
            label.d-block: strong 약관
            //- pre.bg-white.border.p-4(style='height: 5rem; font-size: 12px; overflow: scroll').
            //-   내용
            //-   내용
            //-   내용
            //-   내용
            //-   내용

            b-form-checkbox(v-model='agree1' value='Y' unchecked-value='N') 서비스이용약관에 동의합니다.
              a.float-right.ml-1(href='#' target='_blank') 서비스이용약관
            b-form-checkbox(v-model='agree3' value='Y' unchecked-value='N') 개인정보보호약관에 동의합니다.
              a.float-right.ml-1(href='#' target='_blank') 개인정보보호약관


        .mb-3
          button.btn.btn-primary.py-4.btn-block(type='submit') 회원가입

</template>

<script>


export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
      agree1: 'N',
      agree2: 'Y',
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
